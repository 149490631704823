body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-soi-blue {
  background-color: #1e3655;
}

.navbar a {
  color: inherit;
  text-decoration: none;
}

.navbar a:hover {
  color: inherit;
}

.clickable {
  cursor: pointer;
}

.no-color-link a {
  color: inherit;
}

.no-color-link a:hover {
  color: inherit;
}

.bg-game-win {
  background-color: rgb(133, 192, 164);
}

.bg-game-draw {
  background-color: rgb(255, 229, 168);
}

.bg-game-loss {
  background-color: rgb(217, 135, 143);
}

.bg-game-pending {
  background-color: rgb(180, 185, 190);
}

.bg-my-bot {
  background-color: rgb(133, 192, 164);
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}
